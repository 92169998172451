<template>
  <div class="activity-my-page" v-loading="loading">
    <fm-title title-text="我的活动" :title-menus="[{key: 'his', label: current ? '往期回顾' : '返回'}]" @clickTitleMenu="current = !current">
      <div class="title-s" v-if="!current">
      <fm-date-picker
        style="width: 200px;"
        absolute
        v-model="year"
        placeholder="请选择"
        type="year"
      ></fm-date-picker>
      </div>
    </fm-title>
    <div class="page-c" v-if="current">
      <div class="a-t">开展中的活动</div>
      <div class="a-list">
        <div class="a-item" @click="tableAction({data: item, action: 'detail'})" v-for="item in dataList.filter(v => v.status === 'doing')" :key="item.id">
          <activity-item :data="item"></activity-item>
          <fm-btn class="a-item-btn" @click="tableAction({data: item, action: 'update'})" v-if="item.canEdit">修改</fm-btn>
        </div>
        <div class="no-data" v-if="dataList.filter(v => v.status === 'doing').length === 0">
          <img draggable="false" src="/static/images/no-data.png" style="width: 100px; margin: 0px auto;">
        </div>
      </div>
      <div class="a-t" style="padding-top: 10px;">未开展的活动</div>
      <div class="a-list">
        <div class="a-item" @click="tableAction({data: item, action: 'detail'})" v-for="item in dataList.filter(v => !v.status || v.status === 'wait')" :key="item.id">
          <activity-item :data="item"></activity-item>
          <fm-btn size="small" @click="tableAction({data: item, action: 'update'})" type="primary" class="a-item-btn" v-if="item.canEdit">修改</fm-btn>
        </div>
        <div class="no-data" v-if="dataList.filter(v => v.status === 'wait').length === 0">
          <img draggable="false" src="/static/images/no-data.png" style="width: 100px; margin: 0px auto;">
        </div>
      </div>
    </div>
    <div class="page-c" v-else>
      <div v-for="month in showMonthList" :key="month">
        <div class="a-t">{{month}}</div>
        <div class="a-list" style="padding-bottom: 10px;">
          <div class="a-item" @click="tableAction({data: item, action: 'detail'})" v-for="item in hisData.filter(v => v.startDate.startsWith(month))" :key="item.id">
            <activity-item :data="item"></activity-item>
          </div>
        </div>
      </div>
    </div>
    <fm-modal v-model="modal" v-if="modal" width="90%" title="活动明细">
      <activity-form
        :source-data="chooseData"
        ref="activityForm"
        :activity-type-list="activityTypeList"
        :readOnly="!chooseData.canEdit"
        :user-list="$store.getters.workerUserList">
      </activity-form>
      <div class="btns" v-if="chooseData.canEdit">
        <fm-btn @click="save" style="margin-right:50px;">保存</fm-btn>
        <fm-btn @click="modal = false">取消</fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import {
  activityRequest as request,
  activityTypeRequest
} from '../../api'

import ActivityForm from './cmp/form'
import ActivityItem from './cmp/item'

export default {
  components: {
    ActivityForm,
    ActivityItem
  },
  data () {
    return {
      year: new Date(),
      current: true,
      activityTypeList: [],
      modal: false,
      chooseData: {},
      dataList: [],
      loading: false,
      hisData: [],
      monthList: [],
      hisDataNoStartDate: []
    }
  },
  created () {
    this.$store.dispatch('loadWorkerUserList')
    this.loadTypes()
    this.loadData()
  },
  computed: {
    showMonthList () {
      return this.year ? this.monthList.filter(v => v.startsWith(this.year.getFullYear())) : this.monthList
    }
  },
  methods: {
    async loadTypes () {
      this.activityTypeList = await activityTypeRequest.get()
    },
    async loadData (parm) {
      this.loading = true
      let datas = []
      if (this.$authFunsProxy.get) {
        datas = await request.get(parm) 
      } else if (this.$authFunsProxy.getMy) {
        datas = await request.getByAuth(parm) 
      }
      datas.forEach(v => {
        v.activityTypeName = v.activityType ? v.activityType.name : null
        v.statusText = (this.$store.getters.activityStatusList.find(v1 => v.status === v1.key) || this.$store.getters.activityStatusList[0]).label
      })
      this.dataList = datas
      this.getHis()
      this.loading = false
    },
    getHis () {
      let hisData = this.dataList.filter(v => v.status === 'end')
      this.hisDataNoStartDate = hisData.filter(v => !v.startDate)
      hisData = hisData.filter(v => v.startDate)
      hisData.sort((a, b) => {
        return new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
      })
      this.hisData = hisData
      this.monthList = []
      hisData.forEach(v => {
        let month = v.startDate.slice(0, 7)
        if (!this.monthList.includes(month)) {
          this.monthList.push(month)
        }
      })
    },
    async tableAction (parm) {
      this.chooseData = Object.assign({}, parm.data)
      if (parm.action === 'update') {
        this.modal = true
      } else if (parm.action === 'detail') {
        this.$router.push({
          name: 'hrms.activity.detail',
          query: {
            type:  this.$authFunsProxy.get ? 'manage' : 'my',
            id: this.chooseData.id
          }
        })
      }
    },
    async save () {
      let data = this.$refs.activityForm.getFormData()
      if (!data) {
        return
      }
      if (data.id) {
        await request.edit(data.id, data)
      }
      this.loadData()
      this.modal = false
    }
  }
}
</script>

<style scoped lang="less">
.btns {
  width: 100%;
  display: flex;
  justify-content: center;
}
.no-data {
  padding: 50px 40px;
}
.a-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.a-item {
  margin: 10px;
  position: relative;
}
.activity-my-page {
  border-radius: 8px;
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background: #fff;
}
.a-t {
  font-size: 18px;
  padding-left: 10px;
}
.a-item-btn {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.title-s {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-c {
  width: 100%;
  height: calc(100% - 48px);
  padding: 0 10px 10px;
  overflow-y: auto;
}
</style>
