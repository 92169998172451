<template>
  <div class="content activity-item">
    <img :src="imgSrc" v-if="imgSrc" class="a-img"/>
    <div class="a-img" v-loading="loadingFile" v-else>未选择图片</div>
    <div class="title">
      {{activityData.name}}
    </div>
    <div class="base-info" style="padding-bottom: 10px;">
      <div class="info-item">
        <div class="label">活动地点：</div>
        <div class="value">{{activityData.address}}</div>
      </div>
      <div class="info-item">
        <div class="label">主办人员：</div>
        <div class="value">{{activityData.hostUserName}}</div>
      </div>
      <div class="info-item" v-if="['end', 'doing'].includes(activityData.status)">
        <div class="label">开始时间：</div>
        <div class="value">{{activityData.startDate ? activityData.startDate.slice(0, 10) : ''}}</div>
      </div>
      <div class="info-item" v-if="['end'].includes(activityData.status)">
        <div class="label">结束时间：</div>
        <div class="value">{{activityData.endDate ? activityData.endDate.slice(0, 10) : ''}}</div>
      </div>
      <div class="info-item" v-if="['end'].includes(activityData.status)">
        <div class="label">受益人数：</div>
        <div class="value">{{(activityData.receiveTotal || '_') + '人'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  fileRequest
} from '@/api'

export default {
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      activityData: {},
      fileIds: [],
      formParm: [],
      otherInfo: {},
      contentList: [],
      imgSrc: null,
      loadingFile: false
    }
  },
  watch: {
    data: {
      handler () {
        let data = JSON.parse(JSON.stringify(this.data))
        this.otherInfo = data.otherInfo ? JSON.parse(data.otherInfo) : {}
        this.fileIds = data.fileIds ? data.fileIds.split(',').map(v => Number(v)) : []
        this.contentList = data.content ? data.content.split('\n') : []
        this.activityData = data
        this.formParm = this.activityData.activityType && this.activityData.activityType.config ? JSON.parse(this.activityData.activityType.config) : []
        if (this.activityData.coverFileId) {
          this.loadFile()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async loadFile () {
      this.imgSrc = null
      this.loadingFile = true
      try {
        let fileData = await fileRequest.download({
          id: this.activityData.coverFileId
        })
        if (fileData) {
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.imgSrc = src
        } else {
          this.imgSrc = null
        }
      } catch (error) {
        this.imgSrc = null
      }
      this.loadingFile = false
    }
  }
}
</script>

<style scoped lang="less">
.activity-item {
  width: 300px;
  cursor: pointer;
  background: #f0f0f0;
  overflow-y: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .title {
    width: 100%;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
  }
  .label {
    padding-left: 10px;
    min-width: 80px;
    color: #666;
  }
  .info-item {
    display: flex;
  }
  .content {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .a-img {
    height: 166px;
    width: 100%;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #ddd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}
</style>
